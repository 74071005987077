import React, {useState} from "react";
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"
import gql from "graphql-tag";
import {Mutation, Query} from "react-apollo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {client} from '../../apollo/client';
import MyAccountLayout from "../../components/myAccountLayout";

const MEMBERSHIP = gql`query Membership {
    membership {
        membership
    }
}`

const UPSERT_MEMBERSHIP = gql`
    mutation UpsertMembership($input: UpsertMembershipInput!) {
        upsertMembership(input: $input) {
            membership
        }
    }`

export default () => {
  const {datoCmsMembershippage: p} = useStaticQuery(query)

  const [membership, setMembership] = useState(false)
  return <MyAccountLayout>
      <div className="flex flex-col justify-center items-center">
          <h1
            className='font-title font-bold text-4xl uppercase py-2 text-center tracking-wider w-full'>Membership</h1>
          <Img fluid={p.image.fluid} className='w-1/2 my-8'/>
          <div className="flex mb-64">
              <Query
                client={client}
                query={MEMBERSHIP}
              >
                  {({loading, data: {membership: m}, error}) => {
                      if (loading) {
                          return <FontAwesomeIcon className='text-gray-800 mx-4' icon={faSpinner} spin={true} size="2x"/>
                      }
                      if (error) {
                          return <div className="font-sans text-red-700">Something went wrong, please try again later</div>
                      }
                      if (m) {
                          setMembership(m.membership)
                      } else {
                          setMembership(false)
                      }
                      return <Mutation
                        client={client}
                        mutation={UPSERT_MEMBERSHIP}
                        refetchQueries={() =>
                          [{
                              query: MEMBERSHIP,
                          }]}
                      >
                          {(upsertMembership, {loading}) => <div className='flex flex-col justify-center items-center'>
                              <label>
                                  <div
                                    className={"inline-block mr-2 border border-black" + (membership ? ' bg-yellow-700' : '')}>
                                      <input
                                        className='invisible'
                                        type="checkbox"
                                        checked={membership}
                                        name='membership'
                                        value='membership'
                                        onChange={() => {
                                            setMembership(!membership)
                                            upsertMembership({variables: {input: {membership: !membership}}})
                                        }}
                                      />
                                  </div>
                                  <span className="font-sans font-light text-xl">{p.message}</span>
                              </label>
                              <FontAwesomeIcon icon={faSpinner} spin={true} size="1x"
                                               className={loading ? "visible" : 'invisible'}/>
                          </div>}
                      </Mutation>

                  }}
              </Query>
          </div>
      </div>
  </MyAccountLayout>;
}

const query = graphql`
    query MembershipPage {
        datoCmsMembershippage{
            image{
                fluid(maxWidth: 570) {
                    ...GatsbyDatoCmsFluid
                }
            }
            message
        }
    }
`